<lib-logo-frame>
    <ng-container form-title>
        Crea un nuovo account
    </ng-container>

    @if (registrationSent()) {
        <div class="my-5 flex flex-row bg-green-50 rounded-lg p-4 ring-1 ring-green-600/20">
            <mat-icon class="shrink-0 text-green-700">email</mat-icon>
            <p class="ml-3 text-sm text-green-700">
              <span class="font-medium">
                Ti &egrave; stata inviata una e-mail con il link per attivare il tuo account.
              </span> 
            </p>
        </div>
    } 
    @else {
        <lib-register-form 
            [bigButton]="true"
            label="REGISTRATI"
            [loading]="loading()"
            (onSubmit)="try_register($event)" />
    }

    @if (error()) {
        <lib-error-alert>{{ error() }}</lib-error-alert>
    }
    

    <a form-footer mat-button color="primary" [routerLink]="['/', ROUTER_TOKENS.LOGIN]">Torna alla pagina di login</a>

</lib-logo-frame>