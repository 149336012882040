import {
	ChangeDetectionStrategy,
	Component,
	DestroyRef,
	OnInit,
	inject,
	signal,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { LogoFrameComponent } from '../logo-frame/logo-frame.component';
import { MatButtonModule } from '@angular/material/button';
// import { ReCaptchaV3Service } from 'ng-recaptcha';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { IRegistration } from '../../model/auth-model';
import { concatMap } from 'rxjs';
import {
	ErrorAlertComponent,
	LoggerService,
} from '@webapp-nx-repo/lib-shared-common';
import { RegisterFormComponent } from '../register-form/register-form.component';
import { AUTH_ROUTER_TOKENS } from '../routes/router-tokens';
import { LibAuthService } from '../../service/lib-auth.service';
import { IUser } from '../../model/user';

@Component({
	selector: 'lib-auth-register',
	imports: [
		CommonModule,
		RouterLink,
		ErrorAlertComponent,
		LogoFrameComponent,
		MatIconModule,
		MatButtonModule,
		RegisterFormComponent,
	],
	providers: [],
	templateUrl: './register.component.html',
	styles: [``],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegisterComponent implements OnInit {
	readonly ROUTER_TOKENS = AUTH_ROUTER_TOKENS;
	private readonly destroyRef = inject(DestroyRef);
	private readonly logger = inject(LoggerService);
	private readonly router = inject(Router);
	private readonly activateRoute = inject(ActivatedRoute);
	private readonly authService = inject(LibAuthService);
	//private readonly recaptchaV3Service = inject(ReCaptchaV3Service);

	loading = signal<boolean>(false);
	error = signal<string | null>(null);
	registrationSent = signal<boolean>(false);

	ngOnInit(): void {
		if (this.authService.isLoggedIn()) {
			this.router.navigateByUrl('/');
		}
	}

	try_register(account_data: IRegistration): void {
		this.loading.set(true);
		this.error.set(null);
		/*
        account_data.grecaptcha_v3_token = '';

        if(environment.production) {
            this.recaptchaV3Service.execute('register')
                .pipe(takeUntilDestroyed(this.destroyRef))
                .subscribe({
                    next: (token: string) => {
                        account_data.grecaptcha_v3_token = token;
                        this.register(account_data);
                    },
                    error: (err) => {
                        this.loading.set(false);                        
                        this.error.set('Qualcosa è andato storto.');
                        this.logger.error(`Something went wrong .. ${err}`);
                    }
                });
        } else {
            this.register(account_data);
        }        
        */
	}

	private register(data: IRegistration) {
		this.authService
			.register(data)
			.pipe(
				takeUntilDestroyed(this.destroyRef),
				concatMap(() => this.authService.getLoggedUser()) // save LoggedUser in authService
			)
			.subscribe({
				next: (userInfo: IUser) => {
					this.loading.set(false);
					this.error.set(null);
					this.registrationSent.set(true); // ti è stata inviata una mail ..
				},
				error: (err: string) => {
					this.loading.set(false);
					this.error.set('Errore durante la registrazione.');
				},
			});
	}
}
