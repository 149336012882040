import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	Output,
	booleanAttribute,
	inject,
	input,
	signal,
} from '@angular/core';
import {
	FormBuilder,
	FormControl,
	FormGroup,
	ReactiveFormsModule,
	Validators,
} from '@angular/forms';
import {
	EMPTY_PASSWORD_CONSTRAINTS,
	IPasswordContraints,
	evalPasswordContraints,
	pwdValidator,
} from '../../utilities/password-utilities';
// import { ReCaptchaV3Service, RecaptchaV3Module } from 'ng-recaptcha';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import {
	InvalidParentErrorMatcher,
	LoadingBigButtonComponent,
	LoadingButtonComponent,
	controlsMatcher,
} from '@webapp-nx-repo/lib-shared-common';
import { IRegistration } from '../../model/auth-model';
import { PwdStatusComponent } from '../pwd-status/pwd-status.component';

@Component({
	selector: 'lib-register-form',
	imports: [
		CommonModule,
		LoadingButtonComponent,
        LoadingBigButtonComponent,
		ReactiveFormsModule,
		MatFormFieldModule,
		MatInputModule,
		MatButtonModule,
		MatIconModule,
		// RecaptchaV3Module,
		PwdStatusComponent,
	],
	templateUrl: './register-form.component.html',
	styles: ``,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegisterFormComponent {
	@Input({ required: true }) label!: string;
	@Input() cancel_label: string = 'Cancel';
	loading = input<boolean>(false);
	@Input({ transform: booleanAttribute }) bigButton: boolean = false;
	@Output() onSubmit = new EventEmitter<IRegistration>();
	@Output() onCancel = new EventEmitter<void>();

	private readonly fb = inject(FormBuilder);

	invalidParentErrorMatcher = new InvalidParentErrorMatcher();
	pwdStatus = signal<IPasswordContraints>(EMPTY_PASSWORD_CONSTRAINTS);

	formData: FormGroup<{
		email: FormControl<string | null>;
		passwordGroup: FormGroup<{
			password: FormControl<string | null>;
			password_confirmation: FormControl<string | null>;
		}>;
	}> = this.fb.group({
		email: this.fb.control<string | null>(null, [
			Validators.required,
			Validators.email,
			Validators.minLength(6),
			Validators.maxLength(191),
		]),
		passwordGroup: this.fb.group(
			{
				password: this.fb.control<string | null>(null, [
					Validators.required,
					pwdValidator,
				]),
				password_confirmation: this.fb.control<string | null>(null, [
					Validators.required,
				]),
			},
			{
				validators: controlsMatcher(
					'password',
					'password_confirmation'
				),
			}
		),
	});

	constructor() {
		this.formData.controls.passwordGroup.controls.password.valueChanges.subscribe(
			(pwd: string | null) => {
				if (pwd) {
					this.pwdStatus.set(evalPasswordContraints(pwd));
				}
			}
		);
	}

	submit() {
		const value: IRegistration = {
			email: this.formData.controls.email.value!,
			password:
				this.formData.controls.passwordGroup.controls.password.value!,
			password_confirmation:
				this.formData.controls.passwordGroup.controls
					.password_confirmation.value!,
		};
		this.onSubmit.emit(value);
	}
}
