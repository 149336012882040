<form [formGroup]="formData" autocomplete="off" novalidate class="flex flex-col">
            
    <mat-form-field>
        <mat-label>Email</mat-label>
        <input inputRefTrimValue matInput type="text" placeholder="nome@esempio.com" formControlName="email" required autocomplete="username">
        <mat-icon matSuffix>email</mat-icon>
        @if(formData.controls.email.errors?.['required']) {
            <mat-error>Inserire email</mat-error>
        }
        @if(formData.controls.email.errors?.['email']) {
            <mat-error>&apos;{{formData.controls.email.value}}&apos; non &egrave; una email valida</mat-error>
        }
        @if(formData.controls.email.errors?.['maxLength']) {
            <mat-error>Email troppo lunga</mat-error>
        }  
    </mat-form-field>

    <div formGroupName="passwordGroup" class="flex flex-col">
        <mat-form-field>
            <mat-label>Password</mat-label>
            <input inputRefTrimValue type="password" matInput formControlName="password" required autocomplete="new-password">
            <mat-icon matSuffix>vpn_key</mat-icon>
            @if(formData.controls.passwordGroup.controls.password.errors?.['required']) {
                <mat-error>Inserire password</mat-error>
            }                    
        </mat-form-field>

        
        <lib-pwd-status [pwd_status]="pwdStatus()" />
        
        
        <mat-form-field>
            <mat-label>Ripeti password</mat-label>
            <input inputRefTrimValue type="password" matInput formControlName="password_confirmation" [errorStateMatcher]="invalidParentErrorMatcher" required autocomplete="new-password">
            <mat-icon matSuffix>vpn_key</mat-icon>
            @if(formData.controls.passwordGroup.hasError('match')) {
                <mat-error>Le password sono diverse</mat-error>
            }
        </mat-form-field>
    </div>

</form>

@if(bigButton) {
    
    <lib-loading-big-button
        class="block mt-5"
        [loading]="loading()" 
        [disabled]="formData.invalid || formData.pristine || loading()" 
        (onClick)="submit()">
        REGISTER
    </lib-loading-big-button>

} @else {
    <div class="mt-8 flex flex-row-reverse">
        <lib-loading-button
            [loading]="loading()" 
            [disabled]="formData.invalid || formData.pristine || loading()" 
            (onClick)="submit()">
            REGISTER
        </lib-loading-button>
            
        <button type="button" mat-stroked-button (click)="onCancel.emit()" class="mr-1">
            {{ cancel_label }}
        </button>
    </div>
}
